import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `10-drimpton-st-davoren-park-south-australia`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const DrimptonStDavorenParkSa5113 = () => (
  <Layout>
    <Seo
      title="NDIS Supported SDA Housing Vacancy in Davoren Park"
      description="Find supportive, comfortable NDIS-supported SDA housing in Davoren Park! Ideal for those needing specialised care, it offers independence and support. Apply now!"
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default DrimptonStDavorenParkSa5113
